.wifi-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100vw;
    height: 100vh;
    padding-top: 150px;

    background-color: #17181a;

    .logo {
        display: flex;
        align-items: center;

        margin: 0 auto 64px;

        img {
            display: inline-block;

            width: 36px;
            margin-right: 8px;

            border-radius: 8px;
        }

        span {
            color: #fff;

            font-size: 28px;
            font-weight: 500;
        }
    }

    .password-input {
        margin-bottom: 32px;

        input {
            width: 52px;
            height: 64px;
            margin: 0 12px;

            text-align: center;

            border-radius: 8px;

            font-size: 40px;
        }
    }

    .description {
        opacity: 0.7;
        color: #fff;

        font-size: 16px;
        font-weight: 500;
    }
}

.iframe-container {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    &.hide {
        transform: translateY(-100%);
    }
}

@primary-color: #1890ff;