.home-container {
    padding: 12px;

    background-color: #bfbfbf;

    .screen {
        display: flex;
        flex-direction: row;

        height: calc(100vh - 24px);

        background-color: #fff;

        .up-split {
            position: absolute;
            top: 12px;
            left: 50%;

            width: 48px;
            height: 48px;

            transform: translateX(-50%);

            .arrow-up {
                position: absolute;
                top: 0;
                left: 50%;

                width: 0;
                height: 0;

                transform: rotate(180deg) translateX(50%);

                border-width: 0 24px 24px;
                border-style: solid;
                border-color: transparent transparent #bfbfbf;
            }

            .right-radius {
                position: absolute;
                // top: 0;
                right: 0;

                width: 48px;
                height: 48px;

                transform: translateX(50%);

                border-radius: 40px;
                background-color: #fff;
            }

            .left-radius {
                position: absolute;
                // top: 0;
                left: 0;

                width: 48px;
                height: 48px;

                transform: translateX(-50%);

                border-radius: 40px;
                background-color: #fff;
            }
        }
    }

    .second-screen {
    }

    .first-screen {
        display: flex;
        flex-direction: row;

        padding: 12px;

        border-radius: 32px;
        background-color: #fff;

        .left {
            width: 50%;
            height: 100%;
            padding-right: 12px;

            .left-inner {
                position: relative;

                width: 100%;
                height: 100%;
            }

            .background {
                position: absolute;

                width: 100%;
                height: 100%;

                .card {
                    overflow: hidden;

                    border-radius: 24px;
                }

                .top {
                    position: relative;

                    display: flex;
                    flex-direction: row;

                    height: calc(61.8% - 6px);
                    margin-bottom: 12px;

                    .left {
                        width: calc(61.8% - 6px);
                        height: 100%;
                        margin-right: 12px;

                        background-color: #e4bfc8;
                    }

                    .right {
                        width: calc(38.2% - 6px);
                        height: 100%;

                        background-color: #f3db8c;
                    }

                    .mask {
                        position: absolute;
                        z-index: 1;
                        top: 50%;
                        left: 50%;

                        width: 61.8%;
                        height: 200%;

                        transform: translateX(-50%) translateY(-50%)
                            rotate(47deg);

                        border: 12px solid #fff;
                        background-color: #dde8eb;
                    }
                }
                .bottom {
                    display: flex;
                    flex-direction: row;

                    height: calc(38.2% - 6px);

                    .left {
                        width: calc(38.2% - 6px);
                        height: 100%;
                        margin-right: 12px;

                        background-color: #badddf;
                    }
                    .right {
                        width: calc(61.8% - 6px);
                        height: 100%;

                        background-color: #51738a;
                    }
                }
            }

            .phone-screen-container {
                position: absolute;
                z-index: 2;
                top: 50%;
                left: 50%;

                width: 44%;

                transform: translateX(-50%) translateY(-50%);

                img {
                    display: block;

                    width: 100%;
                }
            }

            .logo {
                position: absolute;
                z-index: 2;
                top: 64px;
                left: 80px;

                display: flex;
                align-items: center;
                flex-direction: row;

                img {
                    display: block;

                    width: 36px;
                    margin-right: 12px;
                }

                span {
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }
        .right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            width: 50%;
            height: 100%;
            padding-top: 64px;
            padding-bottom: 80px;

            .header {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                padding: 0 80px;

                .button {
                    display: inline-block;

                    padding: 6px 12px 6px 12px;

                    cursor: pointer;

                    border: 1px solid #dadadc;
                    border-radius: 6px;

                    font-size: 14px;
                    font-weight: 500;

                    &:hover {
                        opacity: 0.7;
                    }

                    &:active {
                        opacity: 1;
                    }
                }
            }

            .middle {
                margin: 0 auto;

                transform: translateY(-100px);

                .title {
                    font-size: 88px;
                    font-weight: 600;
                    line-height: 2;
                }

                .description {
                    font-size: 22px;
                }
            }

            .bottom {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                padding: 0 80px;

                .app-store-download-button {
                    cursor: pointer;

                    &:hover {
                        opacity: 0.7;
                    }

                    &:active {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

@primary-color: #1890ff;