* {
    box-sizing: border-box;

    outline: none;

    -webkit-tap-highlight-color: transparent;
}

html,
body {
    height: 100%;
    // 参考了 WCAG 的标准，将正文文本、标题和背景色之间保持在了 7:1 以上的 AAA 级对比度。
    // 默认颜色为 @Black 85%

    color: #262626;
    // 优先使用系统默认的界面字体，同时提供了一套利于屏显的备用字体库，
    // 来维护在不同平台以及浏览器的显示下，字体始终保持良好的易读性和可读性，
    // 体现了友好、稳定和专业的特性。

    font-family: PingFang SC, -apple-system, SF UI Text, Lucida Grande, STheiti,
        Microsoft YaHei, sans-serif;
    // 基于电脑显示器阅读距离（50 cm）以及最佳阅读角度（0.3）默认设置字体大小 14，
    // 以保证在多数常用显示器上的用户阅读效率最佳。
    font-size: 12px;
    line-height: 1.57;
    // 在中后台系统中，数字经常需要进行纵向对比展示，我们单独将数字的字体
    // font-variant-numeric 设置为 tabular-nums，使其为等宽字体。

    font-variant-numeric: tabular-nums;
    // 以上规则参考网址
    // https://ant.design/docs/spec/font-cn
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
}

body {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

@primary-color: #1890ff;